import * as React from 'react'
import { PreviewStoreProvider } from 'gatsby-source-prismic'

export const wrapRootElement = ({ element }) => (
    <PreviewStoreProvider>{element}</PreviewStoreProvider>
);

const TRANSITION_LENGTH = 1500;

// Overriding Gatsby forced scroll position on Link transition
export const shouldUpdateScroll = ({
  prevRouterProps: { ...prevRouterProps },
  routerProps: { ...routerProps },
}) => {
    let prevProject;
    let currentProject;

    if (prevRouterProps) {
        if (prevRouterProps.location) { prevProject = prevRouterProps.location.pathname.indexOf("/project/") > -1 };
        currentProject = routerProps.location.pathname.indexOf("/project/") > -1;
    }

    // accommodating project transition animation duration
    if (prevProject && currentProject) {
        setTimeout(function () {
            document.documentElement.scrollTop = 0;
            document.body.parentNode.scrollTop = 0;
            document.body.scrollTop = 0;
        }, TRANSITION_LENGTH);

    } else {
        document.documentElement.scrollTop = 0;
        document.body.parentNode.scrollTop = 0;
        document.body.scrollTop = 0;
    }

    return false
};
